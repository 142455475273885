$matador-main: #706aff;
$matador-main-dark: #6a66d0;
$red: #d93025;
$blackish: rgba(0, 0, 0, 0.7);
.MuiAppBar-root.MuiAppBar-positionStatic {
  position: fixed;
  left: 20px;
  top: 20px;
  width: auto;
  z-index: 10000;
  background: #222222;
  ​ form {
    margin-right: 20px;
  }
  ​ .MuiIconButton-root {
    background: #333333;
    &:hover {
      background: #373737;
    }
  }
}
aside {
  video {
    border-radius: 5px;
    border: 2px solid #222222;
    height: auto !important;
    max-height: 100vh;
  }
}
.join-room-screen {
  padding: 30px 0;
}

main {
  position: relative;
  .main-video video {
    position: absolute;
    height: calc(100vh - 100px) !important;
    top: 0;
    z-index: 3;
  }
  .buttons-wrapper {
    z-index: 4 !important;
    button {
      border: 1px solid #dadce0;
      background-color: rgba(255, 255, 255, 0.25) !important;
      color: #fff;
      box-shadow: none;
      margin: 2px 8px 3px;
      &.MuiFab-primary {
        background-color: $red !important;
        border: 1px solid $red;
        color: #fff !important;
        width: 75px;
        height: 75px;
      }
      &.disabled,
      &:disabled,
      &[disabled] {
        border: 1px solid #dadce0 !important;
        background-color: rgba(100, 100, 100, 0.25) !important;
        color: #eeeeee !important;
        &:hover {
          border: 1px solid #dadce0 !important;
          background-color: rgba(100, 100, 100, 0.25) !important;
          color: #eeeeee !important;
        }
      }
    }
  }
}
.buttons-wrapper {
  justify-content: center;
  align-items: center;
  button {
    -webkit-transition: background-color 200ms ease-out 200ms;
    -moz-transition: background-color 200ms ease-out 200ms;
    -o-transition: background-color 200ms ease-out 200ms;
    transition: background-color 200ms ease-out 200ms;
    -webkit-transition: border 200ms ease-out 200ms;
    -moz-transition: border 200ms ease-out 200ms;
    -o-transition: border 200ms ease-out 200ms;
    transition: border 200ms ease-out 200ms;
    &:hover {
      background-color: lighten($matador-main, 5%) !important;
      border: 1px solid lighten($matador-main, 5%);
      color: rgba(255, 255, 255, 0.7) !important;
    }
    &.needs-attention {
      background-color: $matador-main-dark !important;
      border: 1px solid $matador-main-dark;
      color: rgba(255, 255, 255, 0.9) !important;
    }
    &.full-screen-button {
      width: 56px;
      height: 56px;
    }
    &.screen-share-button {
    }
  }
}
.join-room-screen {
  background-color: $matador-main;
  height: 100vh !important;
  justify-content: center;
  .logo-holder {
    padding: 20px;
    width: 200px;
  }
  .video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  main {
    height: auto !important;
    width: 650px !important;
    min-height: 370px;
    margin: 0 15px;
    background-color: $blackish;
    border-radius: 4px;
    overflow: hidden;
    &:after {
      width: 120px;
      height: 120px;
    }
    video {
      height: auto !important;
      min-height: 350px;
    }
    .buttons-wrapper {
      bottom: 20px;
    }
  }
  header {
    position: static !important;
    margin: 0 15px;
    padding: 0 0 15px;
    background-color: #fff !important;
    width: 385px !important;
    border-radius: 4px;
    h2 {
      margin: 20px 0 0;
      color: $blackish;
      text-align: center;
    }
    p {
      color: $blackish;
      text-align: center;
      margin: 5px 0;
    }
    form {
      flex-direction: column;
      width: 100%;
      label {
        color: $blackish !important;
      }
      label + div {
        color: $blackish !important;
      }
      input {
        border-bottom: 1px solid;
      }
      button {
        margin: 16px 0;
        color: #fff !important;
        background-color: $matador-main !important;
        border-radius: 30px;
        padding: 10px 18px;
      }
      .MuiCircularProgress-svg {
        color: $matador-main;
      }
    }
  }
}
.room-screen {
  background-color: $matador-main;
  overflow: hidden;
  width: 100%;
  .video-wrapper {
    background-color: $blackish;
  }
  aside {
    top: 0;
    right: 0;
    background: #fff;
    bottom: inherit;
    padding: 0 5px 0;
    border-radius: 0 0 0 4px;

    > div {
      background: #333 !important;
      margin: 5px 0;
    }
  }
  .buttons-wrapper {
    background: #fff;
    bottom: 0;
    padding: 10px 0;
    width: 100%;
    position: absolute;
  }
  main {
    div.showControls {
      z-index: 4 !important;
      button {
        color: #9e9d9d;
      }
    }
  }
}
.MuiCircularProgress-svg {
  color: $matador-main;
}
@media only screen and (max-width: 943px) {
  .join-room-screen {
    .video-wrapper {
      flex-direction: column;
    }
    header,
    main {
      margin: 0 auto 15px;
      width: 550px !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .buttons-wrapper {
    button,
    button.full-screen-button {
      margin: 2px !important;
    }
  }
  .room-screen {
    overflow-y: hidden;

    .buttons-wrapper {
      button {
        border: 1px solid #dadce0;
        color: #9e9d9d;
        box-shadow: none;
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .join-room-screen {
    padding: 0;
    background-color: inherit !important;
    overflow: hidden;

    .video-wrapper {
      height: auto;
      display: block;
      flex-direction: column;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;

      header,
      main {
        margin: 0 auto 15px;
        width: 100% !important;
        display: flex;
        justify-content: center;
      }
      main {
        min-height: auto;
        margin: 0;
        height: 60vh !important;
        video {
          height: 100% !important;
          min-height: auto;
        }
      }
      header {
        border-radius: 0;
        height: 40vh !important;
      }
    }
  }

  .room-screen {
    .video-wrapper {
      aside {
        width: 110px !important;
        > div {
          width: 100px !important;
          height: 85px !important;
          > div {
            h4 {
              font-size: 8px !important;
              span {
                width: 8px !important;
                height: 8px !important;
              }
            }
            svg {
              width: 12px !important;
              height: 12px !important;
            }
          }
        }
      }
    }
  }
}
